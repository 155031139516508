/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
import axios from 'axios';
import { API_EXPERTS, API_UPLOADS } from '../../../shared/ApIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function OurExperts() {
  /* ******************************** HOOKS ******************************* */
  const [experts, setExperts] = useState();
  /* ******************************** CONSTANTS ******************************* */
  const { t } = useTranslation();
  const { title } = t('experts', {
    returnObjects: true,
  });

  useEffect(() => {
    (async function getExperts() {
      try {
        const { data } = await axios.get(API_EXPERTS);
        setExperts(data?.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  function List() {
    return (
      experts?.map(({ id, attributes }) => (
        <Col
          xl={4}
          xxl={4}
          lg={4}
          md={12}
          sm={12}
          xs={12}
          className="our-experts-contain"
          key={id}
          align="center"
          justify="center"
        >
          <div className="our-experts-contain-img">
            <img
              src={API_UPLOADS(attributes?.image?.data?.attributes?.url)}
              alt="Daf time share"
            />
            <div className="our-experts-contain-desc">
              <h2 className="our-experts-contain-desc-title">
                {attributes?.fullName}
              </h2>
              <p className="our-experts-contain-desc-description">
                {attributes?.description}
              </p>
            </div>
          </div>
        </Col>
      )) ?? null
    );
  }
  return (
    <Container className="our-experts">
      <h1 className="our-experts-title" align="center">
        {title}
      </h1>
      <Row justify="center">
        <List />
      </Row>
    </Container>
  );
}

OurExperts.propTypes = {};

OurExperts.defaultProps = {};

export default OurExperts;
