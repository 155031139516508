/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Hero() {
  /* ******************************** CONSTANTS ******************************* */
  const { t } = useTranslation();
  const { title, description } = t('hero', {
    returnObjects: true,
  });

  return (
    <Container className="heroDaf" fluid>
      <div className="container">
        <Row align="center" justify="center" flex-direction="column">
          <h1 className="heroDaf-title">{title}</h1>
          <Col lg="content" justify="center">
            <p className="heroDaf-description">{description}</p>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
